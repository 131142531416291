<template>
  <v-app class="lato-font">
    <div class="row marg-0 padd-t-128">
      <div class="col-md-7 col-sm-12 col-12  padd-0 h-304">
        <div class="row marg-0 align-center">
          <div class="marg-0">
            <div>
              <span class="fsize24 marg-0 clr-000000">Your Details </span>
            </div>
            <form @submit.prevent="detaiLs">
              <div class="w-100 h-64 marg-t-32">
                <div class="l-height-16 marg-b-4">
                  <span class="fsize14 l-height padd-l-8 clr-55585a">Name</span>
                </div>
                <input
                  type="text"
                  class="w-336 h-40 bor-radius padd-8-16 border-input fsize14 box-shado w-100per"
                  placeholder="Please enter the Name"
                  autocomplete="off"
                  onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
                  v-model="user.name"
                  id="name"
                  value="name"
                  :class="{
                    'is-invalid': submitted && $v.user.name.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.user.name.$error"
                  class="invalid-feedback marg-0 l-height-20"
                >
                  <span v-if="!$v.user.name.required" class="validatemessage"
                    >Please enter your name</span
                  >
                </div>
              </div>
            </form>
            <form @submit.prevent="detaiLs">
              <div class="w-100 h-64 marg-t-24">
                <div class="l-height-16 marg-b-4">
                  <span class="fsize14 l-height padd-l-8 clr-55585a"
                    >E-Mail</span
                  >
                </div>
                <input
                  type="text"
                  class="w-336 h-40 bor-radius padd-8-16 border-input fsize14 box-shado w-100per"
                  placeholder="Please enter the E-Mail"
                  autocomplete="off"
                  id="email"
                  name="email"
                  v-model="user.email"
                  :class="{
                    'is-invalid': submitted && $v.user.email.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.user.email.$error"
                  class="invalid-feedback marg-0"
                >
                  <span v-if="!$v.user.email.required" class="validatemessage"
                    >Please enter your email
                  </span>
                  <span v-if="!$v.user.email.email" class="validatemessage"
                    >Please enter your valid emailID
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          <button
            class="btn-color fsize18 padd-0-16 bor-radius h-40 marg-t-38 w-100per"
            @click="detaiLs()"
          >
            Continue
          </button>
        </div>
      </div>
      <div class="col-md-5 col-sm-12 padd-0 hideimgmob">
        <img class="desktopimg" src="..\assets\Desktop-login.svg" alt="" />
      </div>
    </div>
  </v-app>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      user: {
        name: this.name,
        email: this.email,
      },
      name: null,
      email: null,
      submitted: true,
    };
  },
  validations: {
    user: {
      name: { required },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    detaiLs() {
      this.submitted = true;
      //Error catch user id is invalid//
      this.loader = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$router.push("/pan");
    },
  },
};
</script>

<style>
</style>